import { useState, useEffect } from 'react';
 import { GuestUserLoginAlert } from '../../common/constants';
 import SweetAlerts from './sweetalerts/sweetalert';

export default function GuestUserLoginToast(props) {
   const [Close, setClose] = useState(false)

   useEffect(() => {

      if (props.show === true) {
          setClose(true)
      }

   }, [props.show])

   const handleCloseAlert = () => {
      setClose(false)
      props.setStateLoginClose(false)
   }

   const login_with_otp = () => {
      props.history.push('/otp-login')
   }

   return (
      <>
         {
            Close && <SweetAlerts
               type={'I'} 
               title={'You are not logged in'}
               message={GuestUserLoginAlert}
               closeAlert={() => handleCloseAlert()}
               button_text={'No Thanks'}
               showAction={true}
               ActionText={'Continue to login'}
               buttonAction={() => login_with_otp()}
            > </SweetAlerts>
         }
      
      </>
   );
}