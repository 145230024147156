import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { commonActions } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class LoginButton extends React.Component {
    
    login_click = () => {
        console.log('this.props ', this.props.showLoginModal )
      //  const { showLoginModal } = this.props;
        // showLoginModal(true)
        
    }
    
    
    render() {
        const { showLoginModal } = this.props;
        return (

            <span>
                Please<Link to="/otp-login" className="color-light-blue text-center cursor-pointer load_more login_button" onClick={() => this.login_click(true)}>login</Link>to see the complete list
            </span>

        )
    }
}

const mapStateToProps = ({ auth, common }) => {
    return {
        auth,
        saveStatus: common.saveStatus
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators(commonActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);