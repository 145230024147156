export const youtubeUrlRegex = /(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/;
export const vimeoUrlRegex = /(http(s)??\:\/\/)?(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
export const youTubeVideoIdRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
export const urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
export const urlRegexWithoutAnchorTag = /((http(s)?(\:\/\/))?(www\.)?([\a-zA-Z0-9-_\.\/])*(\.[a-zA-Z]{2,3}\/?))([\a-zA-Z0-9-_\/?=&#])*(?!(.*a>)|(\'|\"))/
export const urlRegexAllintmlTag = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/
export const emailRegex = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
export const apiStatus = { UNDEFINED: "UNDEFINED", IN_PROGRESS: "IN_PROGRESS", COMPLETED: "COMPLETED", ALREADY_EXISTS: "ALREADY_EXISTS" };
export const eventVotes = { INTERESTED: "interested", GOING: "going", NOTSURE: "notsure" }
export const generalMailServer = [ "@gmail", "@googlemail", "@yahoo", "@yahoomail", "@outlook", "@msn", "@aol", "@icloud", "@titan", "@zoho" ];

// default messages 

export const GuestUserLoginAlert = 'Please login for complete access of Mappes';