import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ToastMessage(props) {
   useEffect(() => {
      if (props.show === true) {
         if(props.type == 'S'){
            toast.success(props.msg);
         }
         
         if (props.type == 'I') {
            toast.info(props.msg);
         }
         
         if (props.type == 'E') {
            toast.error(props.msg);
         }
         
         if (props.type == 'W') {
            toast.warning(props.msg);
         }
         
      }

   }, [props.show])

   return (
      <>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         /> 
      </>
   );
}