import React from 'react';
import { Button, Search } from 'semantic-ui-react';
import axios from 'axios';
import { SvgIcon } from '..';
import { BASE_URL } from '../../../config/config';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { commonActions, productActions } from '../../../actions';
import { apiStatus } from '../../../common/constants';
import AddNewProduct from './AddNewProduct';
import SweetAlerts from '../../common/sweetalerts/sweetalert';
import './index.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GuestUserLoginToast from '../../common/GuestUserLoginAlert';

class AddProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            modalOpen: false,
            addRawButtonLoading: false,
            resultsRendered: 4,
            showLoadMore: true,
            condition: false,
            LastEvaluatedKey: props.supplier && props.supplier.LastEvaluatedKey,
            products: [],
            showSupplierLoader: true,
            product_submit: false,
            product_update_submit: false,
            showLoginAlert: false,

        };
    }


    onSubmit = () => {
        const { auth, onSubmit, productId, productName/* , showLoginModal */ } = this.props;
        if (!auth) {
            /* showLoginModal(true); */
            this.ShowLogin(true);
        }
        else {
            if (this.state.products.length > 0) {
                onSubmit(this.state.products, productId, productName);
                this.clearList();
                setTimeout(() => {
                    this.handleUpdateSucess();
                }, 500);
            } else {
                console.log('in here')
                toast.error('Please add a product to continue !', {
                    autoClose: 3000,
                });
            }
        }
    }

    clearList = () => {
        this.setState({ products: [] })
    }

    removeProduct = (product) => {
        const index = this.state.products.findIndex(a => a.company_id === product.company_id);
        const products = [...this.state.products];
        products.splice(index, 1);
        this.setState({ products });
    }

    handleResultSelect = (data) => {

        if (!data.value) {
            return;
        }
        this.setState({ value: data.value });
        const selectedValue = data
        let products = [...this.state.products];
        if (selectedValue) {
            products.push({
                id: selectedValue.key,
                name: selectedValue.name,
                country: selectedValue.country
            });

        }
        this.setState({
            products,
            value: '',
            results: [],
            searchQuery: '',
        });
    };

    handleSearchChange = (e) => {
        const searchQuery = e.target.value;
        this.setState({ searchQuery: searchQuery, value: searchQuery, isLoading: true });
        axios.get(`${BASE_URL}/api/v1/product/search/${searchQuery}`, { withCredentials: true }).then(response => {
            const result = response.data && response.data.length > 0 && response.data.map(k => {
                return {
                    key: k._source.id,
                    value: k._source.id,
                    text: k._source.name,
                    name: k._source.name
                }
            })
            this.setState({
                isLoading: false,
                results: result,
            });


        });
    };

    openCreatePopup = () => {
        console.log('in open create ')
        this.setState({
            open: true,
            value: '',
            results: [],
            searchQuery: '',
        });
    }

    ShowLogin = (flag) => {
        if (flag == true) {
            this.setState({ showLoginAlert: true })
        }
    }

    setStateLoginClose = () => {
        this.setState({ showLoginAlert: false })
    }

    renderlist = (result, search) => {
        if (search != '') {
            return (
                <span>
                    <div >
                        <span className="text-primary addnewcompany-link" onClick={() => this.openCreatePopup()}> + Create a New Product </span>
                        <label>{search}</label>
                    </div>
                    {result && result.length > 0 &&
                        <ul>
                            {result.map((product) => {

                                return <li onClick={() => this.handleResultSelect(product)}>
                                    {product.name}
                                </li>
                            })
                            }
                        </ul>
                    }
                </span>
            )
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleSucess = () => {
        this.setState({ product_submit: true });
    }

    handleCloseAlert = () => {
        this.setState({ product_submit: false })
    }


    handleUpdateSucess = () => {
        this.setState({ product_update_submit: true });
    }

    handleUpdateCloseAlert = () => {
        this.setState({ product_update_submit: false })
    }

    render() {
        const { isLoading, value, results, products, product_submit, product_update_submit, searchQuery } = this.state;
        const { saveStatus, productName, productId, companyName } = this.props;
        const { open } = this.state;

        return (
            <span>
                <div>
                    <ToastContainer />
                </div>
                <div className="supplier-head-add mb-3">
                    <div className='supplier-head-add-inner'>

                        <div>
                            <h3>Help us build the product catalogue.</h3>
                            <p>Are there more products from {companyName}? Create a product catalogue by adding products.</p>
                        </div>
                        <div className="w-100 mt-3 d-flex justify-content-between">
                            {open &&
                                <AddNewProduct
                                    productId={productId}
                                    open={open}
                                    handleClose={this.handleClose}
                                    type="Applications"
                                    handlePSucess={this.handleSucess}
                                />
                            }

                            {/* <Dropdown
                                icon="search"
                                fluid
                                loading={isLoading}
                                search
                                selection
                                className="selectsupplier-drop"
                                placeholder='Search'
                                onChange={this.handleResultSelect}
                                onSearchChange={this.handleSearchChange}
                                options={results}
                                value={value}
                                allowAdditions
                                additionLabel={
                                    <span className="text-primary addnewcompany-link" onClick={() => this.setState({ open: true })}> + Create a New Product </span>
                                }
                                noResultsMessage={null}
                            /> */}
                            <div className='w-100 product-search-alt'>
                                <Search
                                    fluid
                                    placeholder="Search here..."
                                    loading={isLoading}
                                    showNoResults={false}
                                    onSearchChange={this.handleSearchChange}
                                    value={value}
                                    minCharacters={3}
                                // onResultSelect={this.handleResultSelect}
                                //   results={results}
                                // resultRenderer={resultRenderer}
                                />
                                <div className="result_list">
                                    {this.renderlist(results, searchQuery)}
                                </div>
                            </div>
                            <Button type='submit' primary onClick={this.onSubmit} loading={saveStatus === apiStatus.IN_PROGRESS}>Add To List</Button>
                        </div>

                        <div className="w-100 selectedsupplier">
                            {
                                products.map(product =>
                                    <span className="badge"><span className="badge-inner">{product.name}<SvgIcon name="close" viewbox="0 0 23.126 23.126" onClick={() => this.removeProduct(product)} /></span></span>
                                )
                            }
                        </div>

                        {
                            products.length > 0 &&
                            <div className="text-right mt-3">
                                <Button type='submit' onClick={this.clearList}>Cancel</Button>
                            </div>
                        }

                    </div>

                </div>
                {
                    product_submit && <SweetAlerts type={'S'} title={'Product has been added successfully'} message={'The product you submitted is undergoing a verification process and will be accessible to everyone once it receives approval'} closeAlert={this.handleCloseAlert}> </SweetAlerts>
                }

                {
                    product_update_submit && <SweetAlerts type={'S'} title={'Success'} message={'Product added successfully'} closeAlert={this.handleUpdateCloseAlert}> </SweetAlerts>

                }
                <GuestUserLoginToast {...this.props} show={this.state.showLoginAlert} setStateLoginClose={() => this.setStateLoginClose()} />
            </span>

        )
    }
}

const mapStateToProps = ({ singleProduct, common, auth }) => {
    return {
        productName: singleProduct.info && singleProduct.info.Item.name,
        saveStatus: common.saveStatus,
        auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ ...productActions, ...commonActions }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);