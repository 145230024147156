// export const BASE_URL = "http://localhost:5000";
//
// export const BASE_URL = "https://devapi.mappes.io";

// export const BASE_URL = "https://api.mappes.io";

let backendHost = '';
const testStripeKey = 'pk_test_51H4VrZGynH849sjgf1nbouOmXEQt0KFbveEzDxPCtveJgK8kwV1TcDfCurvt58mEgFUb8zOSi9NUkV3lDQ30D9oY00ZfK5SkNu';
const prodStripeKey = 'pk_live_51H4VrZGynH849sjgHT1No5uAwEet37W7LgLb0FCdtfSJ6g0kUE63qQgAaVk8YQlOmLmIBGHOWlpnN6pLzsLET4ri007CI21CeS';
const fresh_chat_token ='299aafd6-41fe-4ef1-a262-5987f8ec67b2';
let stripeKey = testStripeKey;
const hostname = window && window.location && window.location.hostname;
if (hostname === 'mappes.io') {
    backendHost = 'https://api.mappes.io';
    stripeKey = prodStripeKey;
} else if (hostname === 'www.mappes.io') {
    backendHost = 'https://api.mappes.io';
    stripeKey = prodStripeKey;
} else if (hostname === 'staging.mappes.io') {
    backendHost = 'https://stagingapi.mappes.io';
} else if (hostname === 'devapp.mappes.io' || hostname === 'devupgrade.mappes.io') {
    backendHost = 'https://devapi.mappes.io';
} else {
    // backendHost = 'https://devapi.mappes.io';
     backendHost = 'http://localhost:5000';
}

export const BASE_URL = `${backendHost}`;
export const STRIPE_KEY = stripeKey;
export const FRESH_CHAT_TOKEN = fresh_chat_token;
