import React from 'react';
import { connect } from 'react-redux';
import { SvgIcon } from '../common';
import { Button } from 'semantic-ui-react';
import { companyActions, productActions, commonActions } from '../../actions';
import { bindActionCreators } from 'redux';

class FollowButton extends React.Component {

    handleFollow = () => {
        const { id, name, type, auth, showLoginModal, followProduct, followCompany } = this.props;
        if (!auth) {
            // showLoginModal(true);
            this.props.ShowLogin(true)
        }
        if (type === "product") {
            if (auth) {
                followProduct(id, name, () => {
                    window.location.reload();
                });
            }
        }
        else {
            if (auth) {
                followCompany(id, name, () => {
                    window.location.reload();
                });
            }
        }
    }

    handleUnfollow = () => {
        const { id, name, type, user } = this.props;

        if (type === "product") {
            this.props.unfollowProduct(id, name, () => {
                window.location.reload();
            });
        }
        else {
            this.props.unfollowCompany(id, name, () => {
                window.location.reload();
            });
        }
    }

    render() {
        const { user, id, type } = this.props;
        if (!id || !user || !type) return null;
        let isItemFollowed = false;
        if (user.followedCompanies && user.followedProducts) {
            isItemFollowed = type === "product" ?
                user.followedProducts.Items.find(product => product.product_id === id) :
                user.followedCompanies[id];
        }

        if (isItemFollowed) {
            return (
                    // <Button primary size="mini" className="follow-btn-new" onClick={this.handleUnfollow}>
                    //     <Button.Content>
                    //         Following
                    //     </Button.Content>
                    // </Button> 
                    <Button id={`${type}-unFollow`} primary size="mini" className="btn-following" onClick={this.handleUnfollow}>
                        <SvgIcon name="following-icon" viewbox="0 0 16.599 16.595" />
                        Following
                    </Button>
            )
        }
        else {
            return (
                // <Button primary size="mini" className="follow-btn-new" onClick={this.handleFollow}><SvgIcon name="plus" viewbox="0 0 23 23" />
                //     <Button.Content visible> Follow </Button.Content>
                // </Button>
                <Button id={`${type}-follow`} primary size="mini" className='btn-follow' onClick={this.handleFollow}>
                    <SvgIcon name="plus2" viewbox="0 0 14 14" /> Follow
                </Button>
            )

        }
    }
}

const mapStateToProps = ({ user, auth }) => {
    return { user, auth };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ ...productActions, ...companyActions, ...commonActions }, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FollowButton);