import React, { Component } from 'react';
import { Button, Loader } from 'semantic-ui-react';
import "./sweetalert.scss";


class SweetAlerts extends Component {
   constructor(props) {
      super(props);
      this.state = {
         errors: [],
         showAlert: true
      }
   }

   handleClose = () => {
      this.setState({ showAlert: false })
      this.props.closeAlert(true)
   }

   render() {
      const { showAlert } = this.state;
      const { type, title, message } = this.props
      return (
         <span>

            <div className='alert-mask'>
               <div className={`alert-card ${showAlert ? "animate" : ""}`}>
                  <div className='alert-icon'>
                     {type == 'E' && (<div className="error-mark">
                        <div className="check-icon">
                           <span className="icon-line line-tip"></span>
                           <span className="icon-line line-long"></span>
                           <div className="icon-circle"></div>
                           <div className="icon-fix"></div>
                        </div>
                     </div>)}
                     {type == 'S' && (
                        <div className="success-checkmark">
                           <div className="check-icon">
                              <span className="icon-line line-tip"></span>
                              <span className="icon-line line-long"></span>
                              <div className="icon-circle"></div>
                              <div className="icon-fix"></div>
                           </div>
                        </div>
                     )}
                  </div>
                  <h1>{title}</h1>
                  <p>{message}</p>
                  <div className='action'>

                     {this.props.button_text != undefined && this.props.button_text != '' ? (
                        <Button type='button' onClick={this.handleClose}>{this.props.button_text}</Button>

                     ) : (
                        <Button type='button' onClick={this.handleClose}>Close</Button>

                     )}
                     {
                        this.props.showAction && (
                           <Button type='button' className='ml-4' primary onClick={()=> this.props.buttonAction()}>{this.props.ActionText}</Button>
                        )}
                  </div>
               </div>
            </div>

         </span>
      )
   }
}

export default SweetAlerts;