import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Grid, Container } from 'semantic-ui-react';

import ProfileInfo from './ProfileInfo';
import AddNewPostContainer from './AddNewPost/AddNewPostContainer';
import PostsContainer from './PostsContainer';
import * as productActions from '../../actions/product/product';
import { getUserProfileInfo, networkFeedActions, companyActions } from '../../actions'
import './profileFeed.css';
import NewUserView from './NewUserView';
import LoginButton from '../common/LoginButton';
class NetworkFeed extends Component {
    feedType;
    entityId;

    constructor(props) {
        super(props);
        this.state = {};
        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        window.scrollTo({ left: 0, top: 0 });
        const { feedType, entityId } = this.props;
        if (feedType === "product" && !this.props.singleProduct.info) {
            this.props.getProductInfo(entityId);
        }
        else if (feedType === "company" && !this.props.info) {
            this.props.getCompanyInfo(entityId);
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.feedType !== this.props.feedType || prevProps.entityId !== this.props.entityId) {
            this.scrollRef.current.scrollIntoView();
        }
    }


    showNewUserView = () => {
        this.setState({ showNewUserView: true })
    };

    render() {
        let { feedType, entityId } = this.props;
        
        let eventEntityType, eventEntityId;

        if (feedType === "global") {
            eventEntityType = "user";
            if (this.props.auth)
                eventEntityId = this.props.auth.id;
        }
        else if (feedType === "new") {
            eventEntityType = "user";
            if (this.props.auth)
                eventEntityId = this.props.auth.id;
        }
        else {
            eventEntityType = feedType;
            eventEntityId = entityId;
        }

        return (
            <span>
            

                <div ref={this.scrollRef} id="scrollRef" />

                {!this.props.auth && feedType !== "product" && feedType !== "company" ?
                    <Grid padded>
                        {/* <span>Please login to view this page</span> */}
                        <LoginButton />
                    </Grid>
                    :

                    <div>
                        {feedType === "user" &&
                            <Grid padded>
                                <ProfileInfo auth={this.props.auth} userId={entityId} />
                            </Grid>
                        }
                        {
                            feedType === "new" || this.state.showNewUserView ?
                                <NewUserView auth={this.props.auth} history={this.props.history} />
                                :
                                <span>
                                    <div className="feeds-wrapper">
                                        {/*   <Grid padded>
                                            <Grid.Row className="background-white extra-padding-sides">
                                                <Grid.Column width={16} className="mT-10">
                                                    <AddNewPostContainer auth={this.props.auth} feedType={feedType} entityId={entityId} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid> */}
                                        <Grid >
                                            <Grid.Row className="">
                                                <Container width={16} className="body-background palm-nudge-sides">
                                                    <AddNewPostContainer auth={this.props.auth} feedType={feedType} entityId={entityId} history={this.props.history} />
                                                </Container>
                                            </Grid.Row>
                                        </Grid>

                                        {/* <Grid padded>
                                        <Grid.Row className="">
                                            <PostsContainer auth={this.props.auth} feedType={feedType} entityId={entityId} showNewUserView={this.showNewUserView} history={this.props.history} />
                                        </Grid.Row>
                                    </Grid> */}
                                        <PostsContainer auth={this.props.auth} feedType={feedType} entityId={entityId} showNewUserView={this.showNewUserView} history={this.props.history} />
                                    </div>
                                </span>
                        }
                    </div>
                }
            </span>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...productActions, ...networkFeedActions, getUserProfileInfo, ...companyActions }, dispatch) }
}
function mapStateToProps({ auth, user, singleProduct, info }) {
    return { auth, user, singleProduct, info };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkFeed);
