import React, { useEffect, useRef, useState, useCallback } from 'react'
/// import * as d3 from "d3"
import Tree from 'react-d3-tree';
import axios from 'axios';
import { BASE_URL } from '../../../config/config';
//import { wrap } from './word-wrap';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import "./NodeStyle.css";
const margin = { top: 40, right: 90, bottom: 40, left: 90 }
const containerStyles = {
    width: "120vw",
    height: "70vh"
};

const nodeSize = { x: 250, y: 80 };
const foreignObjectProps = { width: 250, height: 50, x: 20 };

export const NodeLinkGraph = ({ applications = [], applicationsCount = 0, product = {} }) => {
    //  const divRef = useRef(null)
    const [treeData, setTreeData] = useState({})
    const [active, setActive] = useState(false)
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const [dimensions, setDimensions] = useState();
    var containerRef = useRef();
    /*   useEffect(() => {
          if (document.getElementById("graph-viz")) {
              const isGraphDrawn = document.getElementById("graph-viz").hasChildNodes()
              if (Object.keys(treeData).length !== 0 && !isGraphDrawn) {
                  drawGraph();
              }
          }
      }, [treeData]) */

    useEffect(() => {
        if (applications.length) {
            const data = {
                name: product.name,
                id: product.id,
                children: applications.map((item) => ({
                    name: item.application_title,
                    id: item.application,
                    children: [],
                    childApplications: item.childApplications,
                }))
            }
            //  console.log('tree data ', data )
            setTreeData(data)
            setTimeout(() => {
                useCenteredTree()
            }, 500);
        }
    }, [applications])

    async function fetchApplications(id) {
        try {
            setActive(true)
            const response = await axios.get(`${BASE_URL}/api/v1/product/application/${id}`, { withCredentials: true });
            const graphData = { ...treeData }
            function findApplication(applicationId, json, node) {
                if (node.id == applicationId) {
                    /*   console.log('node ', node)
                      console.log('applicationId ', applicationId)
                      console.log('applicationId ', json) */
                    const xyz = json.map(app => ({
                        name: app.application_title,
                        id: app.application, // app.rawMaterial,
                        children: [],
                        childApplications: app.childApplications,
                    }))
                    node.children = xyz
                    setTreeData(graphData);
                }
                else {
                    for (var i = 0; i < node.children.length; i++) {
                        findApplication(applicationId, json, node.children[i]);
                    }
                }
            }
            findApplication(id, response.data.Items, graphData)

            return response.data.Items;
        } catch (error) {
            console.log(error)
        }
    }

    const useCenteredTree = () => {
        let width = containerRef.current.clientWidth
        let height = containerRef.current.clientHeight
        setDimensions({ width, height });
        setTranslate({ x: width / 50, y: height / 3 });
    }

    /* function drawGraph() {
        const minChildren = 8;
        const additionalChildren = applicationsCount - minChildren;
        const baseHeight = 1800, baseWidth = 1800;
        // increase height as per no of children and also for nested children
        const height = baseHeight + (additionalChildren * 30)
        // increase width for nested multiple children
        const width = baseWidth;

        const svg = d3.select("#graph-viz").append("svg")
            .attr("width", width + margin.right + margin.left)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(200,50)");

        let i = 0, duration = 1500;
        // console.log(treeData)

        // declares a tree layout and assigns the size
        var treeMap = d3.tree().size([height, width]);

        // Assigns parent, children, height, depth
        let root = d3.hierarchy(treeData, function (d) { return d.children; });
        root.x0 = height / 2;
        root.y0 = 0;

        // Collapse after the second level
        root.children.forEach(collapse);

        update(root);

        // Collapse the node and all it's children
        function collapse(d) {
            if (d.children) {
                d._children = d.children
                d._children.forEach(collapse)
                d.children = null
            }
        }

        function update(source) {

            // compute the new height
            let levelWidth = [1];
            const childCount = (level, n) => {
                if (n.children && n.children.length > 0) {
                    if (levelWidth.length <= level + 1) levelWidth.push(0);
                    levelWidth[level + 1] += n.children.length;
                    n.children.forEach(function (d) {
                        childCount(level + 1, d);
                    });
                }
            };
            childCount(0, root);
            let newHeight = d3.max(levelWidth) * 60; // 60 pixels per line  
            treeMap = treeMap.size([newHeight, width]);

            // Assigns the x and y position for the nodes
            var _data = treeMap(root);

            // Compute the new tree layout.
            var nodes = _data.descendants(),
                links = _data.descendants().slice(1);

            // Normalize for fixed-depth.
            nodes.forEach(function (d) { d.y = d.depth * 180 });

            // ****************** Nodes section ***************************

            // Update the nodes...
            var node = svg.selectAll('g.node')
                .data(nodes, function (d, index) {
                    if (d.parent) {
                        return `${d.data.id}-${d.parent.data.id}`;
                    }
                    return d.data.id
                });

            // Enter any new modes at the parent's previous position.
            var nodeEnter = node.enter().append('g')
                .attr('class', 'node')
                .attr("transform", function (d) {
                    return "translate(" + source.y0 + "," + source.x0 + ")";
                })
                .on('click', (d, e) => click(d, e));

            // Add Circle for the nodes
            nodeEnter.append('circle')
                .attr('class', 'node')
                .attr('r', 1e-6)
                .style("fill", function (d) {
                    return d.childApplications ? "lightsteelblue" : "#fff";
                });

            // Add labels for the nodes
            nodeEnter.append('text')
                .attr("dy", ".35em")
                .attr("x", function (d) {
                    return -13
                    // return d.children || d._children ? -13 : 13;
                })
                .attr("text-anchor", function (d) {
                    return "end"
                    // return d.children || d._children ? "end" : "start";
                })
                .text(function (d) { return d.data.name; })
                .call(wrap);

            // UPDATE
            var nodeUpdate = nodeEnter.merge(node);

            // Transition to the proper position for the node
            nodeUpdate.transition()
                .duration(duration)
                .attr("transform", function (d) {
                    return "translate(" + d.y + "," + d.x + ")";
                });

            // Update the node attributes and style
            nodeUpdate.select('circle.node')
                .attr('r', 10)
                .style("fill", function (d) {
                    if (d._children) {
                        return "lightsteelblue"
                    } else if (d.data.childApplications && !d.children) {
                        return "lightsteelblue"
                    }
                    return "#fff"
                })
                .attr('cursor', 'pointer');


            // Remove any exiting nodes
            var nodeExit = node.exit().transition()
                .duration(duration)
                .attr("transform", function (d) {
                    return "translate(" + source.y + "," + source.x + ")";
                })
                .remove();

            // On exit reduce the node circles size to 0
            nodeExit.select('circle')
                .attr('r', 1e-6);

            // On exit reduce the opacity of text labels
            nodeExit.select('text')
                .style('fill-opacity', 1e-6);

            // ****************** links section ***************************

            // Update the links...
            var link = svg.selectAll('path.link')
                .data(links, function (d) {
                    if (d.parent) {
                        return `${d.data.id}-${d.parent.data.id}`;
                    }
                    return d.data.id
                });

            // Enter any new links at the parent's previous position.
            var linkEnter = link.enter().insert('path', "g")
                .attr("class", "link")
                .attr('d', function (d) {
                    var o = { x: source.x0, y: source.y0 }
                    return diagonal(o, o)
                });

            // UPDATE
            var linkUpdate = linkEnter.merge(link);

            // Transition back to the parent element position
            linkUpdate.transition()
                .duration(duration)
                .attr('d', function (d) { return diagonal(d, d.parent) });

            // Remove any exiting links
            var linkExit = link.exit().transition()
                .duration(duration)
                .attr('d', function (d) {
                    var o = { x: source.x, y: source.y }
                    return diagonal(o, o)
                })
                .remove();

            // Store the old positions for transition.
            nodes.forEach(function (d) {
                d.x0 = d.x;
                d.y0 = d.y;
            });

            // Creates a curved (diagonal) path from parent to the child nodes
            function diagonal(s, d) {
                return `M ${s.y} ${s.x} C ${(s.y + d.y) / 2} ${s.x}, ${(s.y + d.y) / 2} ${d.x}, ${d.y} ${d.x}`;
            }

            // Toggle children on click.
            function click(event, d) {
                if (d.children === undefined) {
                    fetchApplications(d.data.id).then((new_data) => {

                        // combine new data with old data, I'm assuming this will update treeData but I'm not sure
                        // d._children = new_data
                        // d.children = null

                        // call the hierarchy on new data so it has the mike magic it needs
                        root = d3.hierarchy(treeData, di => di.children)

                        // add the ._children attribute
                        // root.children.forEach(collapse)

                        update(root)
                        setActive(false)
                        // if (d.children) {
                        //     d._children = d.children;
                        //     d.children = null;
                        // } else {
                        //     d.children = d._children;
                        //     d._children = null;
                        // }
                    })
                } else {
                    if (d.children) {
                        d._children = d.children;
                        d.children = null;
                    } else {
                        d.children = d._children;
                        d._children = null;
                    }
                }
                update(d);
            }
        }
    } */

    const getChild = (node, toggleNode) => {
         if (node.children == undefined || node.children == null || node.children.length == 0) {
            if (node._children == undefined || node._children == null || node._children.length == 0) {
                fetchApplications(node.id).then((new_data) => {
                    setActive(false)
                })
            } else {
                toggleNode(node)
                node.__rd3t.collapsed = !node.__rd3t.collapsed
                if (node.children) {
                    node._children = node.children;
                    node.children = null;
                } else {
                    node.children = node._children;
                    node._children = null;
                }
            }

        } else {
            toggleNode(node)
            node.__rd3t.collapsed = !node.__rd3t.collapsed
            if (node.children) {
                node._children = node.children;
                node.children = null;
            } else {
                node.children = node._children;
                node._children = null;
            }
        }
    }

    /* const Hexagon = ({ cx, cy, r, color, cursor, nodeDatum, toggleNode }) => {
         const points = Array.from({ length: 6 }, (_, i) => {
            const angle = (i * 2 * Math.PI) / 6; // 360 degrees divided by 6
            const x = cx + r * Math.cos(angle);
            const y = cy + r * Math.sin(angle);
            return `${x},${y}`;
        }).join(" ");

        return (
            <svg width="100" height="100">
                 <polygon
                    points={points}
                    fill={color}
                    onClick={() => alert('in here ')}
                    style={{ cursor: cursor }}
                />
            </svg>
        );
    };
 */

    const renderCustomisedNodeElement = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps,
        getChild
    }) => (
        <g>

            {nodeDatum.name != undefined && (nodeDatum.childApplications == true || nodeDatum.children.length > 0 || product.id == nodeDatum.id) ?
                (<circle fill="darkblue" r="15" onClick={() => getChild(nodeDatum, toggleNode)} />
                    // <Hexagon cx={30} cy={30} r={18} color={'darkblue'} cursor={'pointer'} nodeDatum={nodeDatum} toggleNode={toggleNode} /> 
                )
                : ''}
            {nodeDatum.name != undefined && !nodeDatum.childApplications && (nodeDatum.children.length == 0 && product.id != nodeDatum.id) ?
                (<circle fill="lightsteelblue" r="15" />
                    // <Hexagon cx={30} cy={30} r={18} color={'lightsteelblue'} cursor={'default'} />
                )
                : ''}
            <foreignObject {...foreignObjectProps}>
                <div > {/* style={{ border: "1px solid black", backgroundColor: "#dedede" }} */}
                    <h3 className='nodeHeading'>{nodeDatum.name}
                    </h3>
                </div>
                {/* {nodeDatum.children && nodeDatum.children.length > 0 && (
                    <button className="ml-2 ui mini  button secondary" onClick={}>
                        {nodeDatum.__rd3t.collapsed ? "+" : "-"}
                    </button>
                )} */}
            </foreignObject>
        </g>
    );


    if (!applicationsCount) {
        return <h4>Please wait while we load applications ...</h4>;
    }
    return (
        <Dimmer.Dimmable as={Segment} dimmed={active}>
            <Dimmer active={active} inverted>
                <Loader>Fetching Applications ...</Loader>
            </Dimmer>
            <div id="graph-viz"
                className="modalHeight"
                style={containerStyles}
                ref={containerRef}
            >
                <Tree
                    data={treeData}
                    pathClassFunc={() => ['custom-link', 'extra-custom-link'].join(' ')}
                    /* onNodeClick={getChild} */
                    //  dimensions={dimensions}
                    translate={translate}
                    nodeSize={nodeSize}
                    orientation="horizontal"
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    renderCustomNodeElement={(rd3tProps) =>
                        renderCustomisedNodeElement({ ...rd3tProps, foreignObjectProps, getChild })
                    }
                />
            </div>
        </Dimmer.Dimmable>
    )
}
