//TODO: Refrefactor needed: all list views are similar, check if it can be refactored
import React, { useState } from 'react';
import NoRawMaterials from './NoRawMaterials';
import RawMaterials from './RawMaterials';
import AddRawMaterials from './AddRawMaterials'
 
function RawMaterialsView({ auth, rawMaterials, productId }) {
    const [showAddRawMaterials, setShowAddRawMaterials] = useState(false)
        console.log('rawMaterials ', rawMaterials )
    console.log('showAddRawMaterials ', showAddRawMaterials )
    return (
        <div className="supplierslist-wrapper border-1-radius">
            {rawMaterials!= undefined && rawMaterials.length > 0
                ? 
                <span>
                    {auth && <AddRawMaterials productId={productId} />}
                    <RawMaterials rawMaterials={rawMaterials} />
                </span>
                : showAddRawMaterials
                    ?  <AddRawMaterials productId={productId} />
                    : auth && <NoRawMaterials setShowAddRawMaterials={setShowAddRawMaterials} />
            }
             
        </div>
    )
}

export default RawMaterialsView;