import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Container, Button, Tab, Loader, Image } from 'semantic-ui-react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { eventActions, commonActions, getUserInfo, getUserProfileInfo } from '../../actions';
import { formatIsoMilisecondOnlyTime, formatIsoMilisecondsWithoutTime, replaceSpaceInName, getDay } from '../../common/helperMethods';
import Navbar from '../common/layout/navbar/Navbar'
import { apiStatus } from '../../common/constants';
import { SvgIcon } from '../common';
import SEO from '../seo/SEO';
import './eventsList.scss'
import MediaQuery from 'react-responsive';

import default_image from '../../assets/images/add-event-default.png'
import LoginButton from '../common/LoginButton';
// import { showLoginModal } from '../../actions/common/common';
import GuestUserLoginToast from '../common/GuestUserLoginAlert';

class eventsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_tab: 'upcomingEvents',
            loading: false,
            showLoginAlert: false,

        }
    }


    componentDidMount() {
        const { getEvent, getAllEvent, eventPagination, setEventPagination } = this.props;
        this.setState({ current_tab: 'upcomingEvents' })
        this.setState({ loading: true });
        eventPagination.start = 0;
        setEventPagination(eventPagination);
        getAllEvent(this.state.current_tab);

    }

    componentDidUpdate() {
        const { user, saveStatus } = this.props;

        if (saveStatus === apiStatus.COMPLETED) {
            setTimeout(() => {
                this.setState({ loading: false });
            }, 500);
        }
    }

    handleLoadMoreClick = () => {
        const { auth, eventPagination, setEventPagination, match, getAllEvent } = this.props;
        const { id } = match.params;
        console.log('handleLoadMore ')
        if (auth) {
            this.setState({ loading: true });
            eventPagination.start += 1;
            setEventPagination(eventPagination);
            getAllEvent(this.state.current_tab);

        } else {
            console.log('in here ')
            //showLoginModal(true)
            this.ShowLogin(true);
        }
    }
    
    ShowLogin = (flag) => {
        if (flag == true) {
            this.setState({ showLoginAlert: true })
        }
    }

    setStateLoginClose = () => {
        this.setState({ showLoginAlert: false })
    }

    selectTab = (e, data) => {
        const { auth, eventPagination, setEventPagination, getAllEvent, resetEvent, getRsvpEvent } = this.props;
        let tabname = '';
        if (data.activeIndex == 0) {
            tabname = 'upcomingEvents';
        }

        if (data.activeIndex == 1) {
            tabname = 'pastEvents';
        }

        if (data.activeIndex == 2) {
            tabname = 'user';
        }
        this.setState({ loading: true });
        this.setState({ current_tab: tabname })
        if (data.activeIndex == 0 || data.activeIndex == 1 || data.activeIndex == 2) {
            resetEvent();

            eventPagination.start = 0;
            setEventPagination(eventPagination);
            getAllEvent(tabname);

        } else {
            getRsvpEvent(auth.id);
        }

    }

    render_slider = (eventdata) => {
        const { image, eventName } = eventdata || {};
        if (image != undefined) {
            if (Array.isArray(image) && image.length > 0) {
                return (
                    <img src={image[0]} className='w-100'   alt={eventName} title={eventName} onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = default_image;
                    }} />
                )
            } else if (!Array.isArray(image)) {
                return (
                    <img src={image} alt={eventName}   className='w-100' title={eventName} onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = default_image;
                    }} />
                )
            } else {
                return (
                    <img src={default_image} alt={eventName}   className='w-100' title={eventName} />
                )
            }
        } else {
            return (
                <img src={default_image} className='w-100'   alt={eventName} title={eventName} />
            )
        }
    }

    renderEventsList = (data) => {
        const { auth, eventPagination } = this.props;
        const { loading } = this.state;
        return (
            <>

                {
                data.length > 0 && (
                    <InfiniteScroll
                        dataLength={data.length}
                        next={this.handleLoadMoreClick}
                        hasMore={data.length < eventPagination.total}
                        scrollableTarget="sidebar-pushable"
                        loader={ auth && <Loader active inline center />}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                {
                                    !auth && (<LoginButton />)
                                }
                            </p>
                        }
                    >
                        <span>
                            <ul className='event-lists'>
                                {data.length > 0 && data.map(event =>
                                    <li>
                                        <Link to={`events/${replaceSpaceInName(event.eventName)}/${event.id}`}>
                                            <div className='card'>
                                                <div className='event-upper'>
                                                    {/*  {event.image ? (<Image src={event.image} className='w-100' alt={event.eventName} />) : <Image src={default_image} alt={event.eventName} />} */}
                                                    {this.render_slider(event)}
                                                </div>
                                                <div className='event-bottom'>
                                                    {/* <div className='tag-list'>
                                                <Link to='/event-list'>Mappes Inc</Link>
                                            </div> */}
                                                        <p>{event.eventName}</p>
                                                        <ul>
                                                            <li>
                                                                {event.eventStartDate && <span> <SvgIcon name='calendar-icon' viewbox='0 0 17 18.667' />   {event.eventStartDay} | {event.eventStartDate} - {event.eventEndDate} </span>}</li>
                                                            <li>  {event.eventStarTime && <span> <SvgIcon name='clock-icon' viewbox='0 0 18.666 18.667' /> {event.eventStarTime} - {event.eventEndTime} {/* (IST) */} </span>}</li>

                                                            {event.eventType == 'online' && <span><li><SvgIcon name='video-icon' viewbox='0 0 20.332 13.667' /> Online</li></span>}
                                                            {event.eventType == 'offline' && <span><li><SvgIcon name='map-icon' viewbox='0 0 18 20' /> {event.location} </li></span>}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                    }

                                </ul>
                            </span>
                        </InfiniteScroll>
                    )}
                {data.length == 0 && (
                    <p style={{ textAlign: 'center' }}>
                        {console.log('loading ', loading)}
                        {!auth && loading == false && <LoginButton />}
                        {auth && loading == false && <span>No Events available</span>}
                        {loading == true && <span>Please wait while we fetch all events</span>}
                    </p>
                )}
            </>
        )
    }


    render() {
        const { allEventCollection, auth, rsvp_list } = this.props;

        let upcoming_event = [], past_event = [], intrested_event = [];
        let rsvp = [];
        if (allEventCollection != undefined) {
            if (allEventCollection != undefined && allEventCollection.length > 0) {
                allEventCollection.forEach(item => {
                    item.eventStartDate = isNaN(item.startDate) ? item.startDate : formatIsoMilisecondsWithoutTime(+item.startDate);
                    item.eventStarTime = (item.startDate) ? formatIsoMilisecondOnlyTime(+item.startDate) : '';
                    item.eventStartDay = getDay(+item.startDate);
                    item.eventEndDate = isNaN(item.endDate) ? item.endDate : formatIsoMilisecondsWithoutTime(+item.endDate);
                    item.eventEndTime = (item.endDate) ? formatIsoMilisecondOnlyTime(+item.endDate) : '';
                    item.eventEndDay = getDay(+item.endDate);

                    item.eventStartDate = (item.eventStartDate != 'Invalid Date') ? item.eventStartDate : '';
                    item.eventEndDate = (item.eventEndDate != 'Invalid Date') ? item.eventEndDate : '';

                    item.eventStarTime = (item.eventStarTime != 'Invalid Date') ? item.eventStarTime : '';
                    item.eventEndTime = (item.eventEndTime != 'Invalid Date') ? item.eventEndTime : '';

                    // conditional filtering of events - start
                    if (this.state.current_tab == 'upcomingEvents') {
                        upcoming_event.push(item);
                    }

                    if (this.state.current_tab == 'pastEvents') {
                        past_event.push(item);
                    }

                    if (this.state.current_tab == 'user') {
                        intrested_event.push(item);
                    }
                    // conditional filtering of events - end
                });
            }
        }
        if (rsvp_list != undefined) {
            if (rsvp_list != undefined && rsvp_list.length > 0) {
                rsvp = rsvp_list;
            }
        }


        const panes = [
            {
                menuItem: 'Upcoming events',
                render: () => <Tab.Pane attached={false}>
                    {this.renderEventsList(upcoming_event)}

                </Tab.Pane>,
            },
            {
                menuItem: 'Past events',
                render: () => <Tab.Pane attached={false} >
                    {this.renderEventsList(past_event)}

                </Tab.Pane>,
            },
            {
                menuItem: 'My interests',
                render: () => <Tab.Pane attached={false} >
                    {auth ? (
                        <span>
                            <h3 className='heading-text'>Based on your following interests, we are showing the list of events related to your profile</h3>
                            {this.renderEventsList(intrested_event)}
                        </span>
                    ) :
                        <p style={{ textAlign: 'center' }}>
                            <LoginButton />
                        </p>
                    }
                </Tab.Pane>,
            },
            {
                menuItem: 'RSVP',
                render: () => <Tab.Pane attached={false} >
                    {auth ? (
                        <span>
                            {this.renderEventsList(rsvp)}
                        </span>
                    ) :
                        <p style={{ textAlign: 'center' }}>
                            <LoginButton />
                        </p>
                    }

                </Tab.Pane>,
            }
        ]

        return (
            <Navbar {...this.props}>
                <SEO robots="noindex, nofollow"
                    canonicalUrl="/event-list"
                />
                <div className="primary-background">
                    <Container className="body-background palm-nudge-sides">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <div className='event-list-wrapper'>
                                        <MediaQuery minWidth={767}>
                                            <div className='event-list-upper'>
                                                <Link className='back-link' to='/'><SvgIcon name='arrow-left' viewbox='0 0 23 17' /> Back</Link>
                                            </div>
                                        </MediaQuery>
                                        <Tab menu={{ secondary: true, pointing: true }} panes={panes} onTabChange={this.selectTab} />
                                        {/* <div className='more-event text-center'>
                                            {auth && allEventCollection != undefined && allEventCollection.length > 0 &&
                                                <Button primary basic onClick={this.handleLoadMoreClick} loading={loading}>Show more events</Button>
                                            }
                                        </div> */}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
                <GuestUserLoginToast {...this.props} show={this.state.showLoginAlert} setStateLoginClose={() => this.setStateLoginClose() } /> 
            </Navbar>
        );
    }
}

function mapStateToProps({ events, user, auth, common }) {
    return { ...events, auth, ...common };
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ getUserProfileInfo, getUserInfo, ...eventActions, ...commonActions }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(eventsList);

